import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerDetailsComponent } from './modules/contact-us/career-details/career-details.component';
import { ContactUsComponent } from './modules/contact-us/contact-us.component';
import { FaqComponent } from './modules/faq/faq.component';
import { HomeComponent } from './modules/home/home.component';
import { OurApproachComponent } from './modules/our-approach/our-approach.component';
import { ProductsComponent } from './modules/products/products.component';
import { RefundPolicyComponent } from './modules/refund-policy/refund-policy.component';
import { TermsOfUseComponent } from './modules/terms-of-use/terms-of-use.component';
import { WhoWeAreComponent } from './modules/who-we-are/who-we-are.component';
// import { CoursesComponent } from './modules/courses/courses.component';
import { CourseCategoryDetailsComponent } from './modules/course-category-details/course-category-details.component';
import { ModuleDetailsComponent } from './modules/module-details/module-details.component';
import { PrivacyPolicyWebsiteComponent } from './modules/privacy-policy-website/privacy-policy-website.component';
import { CourseDetailsComponent } from './modules/course-details/course-details.component';
import { MyCoursesComponent } from './modules/my-courses/my-courses.component';
import { ShowCourseComponent } from './modules/show-course/show-course.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { CareerContactComponent } from './modules/career-contact/career-contact/career-contact.component';
import { BlogComponent } from './modules/blog/blog.component';
import { BlogDetailsComponent } from './modules/blog/blog-details/blog-details.component';
import { GIZFormComponent } from './modules/registration-forms/giz-form/giz-form.component';
import { FffaiFormComponent } from './modules/registration-forms/fffai-form/fffai-form.component';
import { CsrLandingPageComponent } from './modules/csr-landing-page/csr-landing-page.component';
import {SelectPlanComponent } from './modules/select-plan/select-plan.component';
import { PortsComponent } from './modules/ports/ports.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'home/:getintouch', component: HomeComponent },
  { path: 'home/#get-in-touch-container', component: HomeComponent },
  { path: 'home/#getInTouchContainer', component: HomeComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'our-approach', component: OurApproachComponent },
  // { path: 'library', component: CoursesComponent },
  { path: 'my-courses', component: MyCoursesComponent },
  {
    path: 'course-category-details/:id',
    component: CourseCategoryDetailsComponent,
  },
  {
    path: 'course/:courseLang/:courseSlug/:moduleSlug',
    component: ModuleDetailsComponent,
  },
  { path: 'module/:moduleId', component: ModuleDetailsComponent },
  { path: 'module-details/:moduleId', component: CourseDetailsComponent },
  { path: 'show/course/:moduleId', component: ShowCourseComponent },
  {
    path: 'show/course/:courseLang/:courseSlug/:moduleSlug',
    component: ShowCourseComponent,
  },
  { path: 'course/:courseLang/:courseSlug', component: CourseDetailsComponent },
  { path: 'course/:courseId', component: CourseDetailsComponent },
  { path: 'course-details/:courseId', component: CourseDetailsComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:blogUrlCategory/:blogSlug', component: BlogDetailsComponent },
  { path: 'blog/:blogId', component: BlogDetailsComponent },
  { path: 'blog-details/:blogId', component: BlogDetailsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'career-contact', component: CareerContactComponent },
  { path: 'career-details/:id', component: CareerDetailsComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyWebsiteComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'skills_4_riders_registration_form', component: GIZFormComponent },
  { path: 'fffai_registration_form', component: FffaiFormComponent },
  { path: 'csr', component: CsrLandingPageComponent },
  { path: 'ports', component: PortsComponent },
  { path: 'select-plan', component: SelectPlanComponent },
  {
    path: 'fffai',
    loadChildren: () =>
      import('./modules/fffai/fffai.module').then((m) => m.FffaiModule),
  },
  {
    path: 'eskill',
    loadChildren: () =>
      import('./modules/eskill/eskill.module').then((m) => m.EskillModule),
  },
  {
    path: 'wai',
    loadChildren: () =>
      import('./modules/wai/wai.module').then((m) => m.WaiModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./modules/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'admin-registration',
    loadChildren: () =>
      import('./modules/admin/admin.module').then(
        (m) => m.AdminModule
      ),
  },
  {
    path: 'industry',
    loadChildren: () =>
      import('./modules/fantasy-map/fantasy-map.module').then(
        (m) => m.FantasyMapModule
      ),
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./modules/gallery/gallery.module').then((m) => m.GalleryModule),
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./modules/media-player/media-player.module').then(
        (m) => m.MediaPlayerModule
      ),
  },
  {
    path: 'job-preference',
    loadChildren: () =>
      import('./modules/job-preference/job-preference.module').then(
        (m) => m.JobPreferenceModule
      ),
  },
  {
    path: 'user-profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./modules/careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'messaging',
    loadChildren: () =>
      import('./modules/messaging/messaging.module').then(
        (m) => m.MessagingModule
      ),
  },
  {
    path: 'scores',
    loadChildren: () =>
      import('./modules/score/score.module').then((m) => m.ScoreModule),
  },
  {
    path: 'user-settings',
    loadChildren: () =>
      import('./modules/user-settings/user-settings.module').then(
        (m) => m.UserSettingsModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'rewards',
    loadChildren: () =>
      import('./modules/rewards/rewards.module').then((m) => m.RewardsModule),
  },
  {
    path: 'webshop',
    loadChildren: () => import('./modules/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'skills',
    loadChildren: () =>
      import('./modules/skills/skills.module').then((m) => m.SkillsModule),
  },
  {
    path: 'ms-webview',
    loadChildren: () =>
      import('./modules/ms-webview/ms-webview.module').then(
        (m) => m.MsWebviewModule
      ),
  },
  {
    path: 'user-certificates',
    loadChildren: () =>
      import('./modules/user-certificates/user-certificates.module').then(
        (m) => m.UserCertificatesModule
      ),
  },
  { path: 'fffai-landing-page', redirectTo: 'fffai' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      // enableTracing: true // Enable to debug routing issues
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
