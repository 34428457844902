<h1><b>Get in touch</b></h1>
<br />
<p *ngIf="!popUpFormEvent" style="text-align: center;">We'd love to hear from you!<br /> Fill in your details to connect
    with us or to request a free consultation.</p>
<p *ngIf="popUpFormEvent" style="text-align: center;">Please fill this form to download our course catalogue.</p>

<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm'>
    <meta name='viewport' content='width=device-width, initial-scale=1.0'>
    <form id='webform1775809000007334001' name='WebToLeads1775809000007334001' accept-charset='UTF-8'>

        <input type='text' style='display:none;' name='xnQsjsdp'
            value='77db35c361a5a4d6fe3c0cbd584c931a3207eced0d95d6c4e89c0ce2289cc383' />
        <input type='hidden' name='zc_gad' id='zc_gad' value='' />
        <input type='text' style='display:none;' name='xmIwtLD'
            value='9c2ca281c49195c156d00537f160d4eb176ab16be043858b3dce9c40968c2ed1e3af2a633eb2f4cceb07d85792c250b5' />
        <input type='text' style='display:none;' name='actionType' value='TGVhZHM=' />
        <input type='text' style='display:none;' name='returnURL' value='null'>

        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <label for="firstName">First name</label>
                <input type='text' id='First_Name' class="form-control" name='First Name' maxlength='40' />
            </div>
        </div>
        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <label for="firstName">Last name</label>
                <input type='text' id='Last_Name' class="form-control" name='Last Name' maxlength='80' />
            </div>
        </div>


        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <label for="email">Email address <span class="text-danger"> *</span></label>
                <input type='text' ftype='email' class="form-control" autocomplete='false' id='Email' name='Email'
                    crmlabel='' maxlength='100' (keypress)="hideErrorForEmail()" />
                <div id="emailError" class="form-text text-danger"></div>
                <p id="emailError" class="form-text text-danger" style="display: none;"></p>
            </div>
        </div>
        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <div class="row">
                    <div class="col-md-4">
                        <label for="phone">Country code <span id="mandatoryStarForCode" style="visibility: hidden;"
                                class="text-danger"> *</span> </label>
                        <input type='text' id='countryCode' name='countryCode' class="form-control" maxlength='30'
                            value='+91' (keypress)="hideErrorForCountryCode()" />
                        <div id="InvalidcountryCodeError" class="form-text text-danger" style="display: none;">Invalid
                            country code.</div>
                    </div>
                    <div class="col-md-8">
                        <label for="phone">Phone number <span id="mandatoryStarForPhone" style="visibility: hidden;"
                                class="text-danger"> *</span></label>
                        <input type='text' id='Phone' name='Phone' class="form-control" maxlength='30'
                            (keypress)="keyPressPhoneNumber($event)" />
                        <!-- (keypress)="hideErrorForPhone()" -->
                        <div id="phoneError" class="form-text text-danger" style="display: none;">Phone number cannot be
                            empty.</div>
                        <div id="invalidPhoneError" class="form-text text-danger" style="display: none;"> Invalid Phone
                            number.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <label for="firstName">Company</label>
                <input type='text' id='Company' class="form-control" name='Company' maxlength='50'>
            </div>
        </div>

        <div class='zcwf_row wfrm_fld_dpNn'>
            <div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'><label for='Lead_Source'>Lead
                    Source</label>
            </div>
            <div class='zcwf_col_fld'>
                <select class='zcwf_col_fld_slt' id='Lead_Source' name='Lead Source'>
                    <option value='-None-'>-None-</option>
                    <option value='Advertisement'>Advertisement</option>
                    <option value='Cold&#x20;Call'>Cold Call</option>
                    <option value='Employee&#x20;Referral'>Employee Referral</option>
                    <option value='External&#x20;Referral'>External Referral</option>
                    <option value='OnlineStore'>OnlineStore</option>
                    <option value='Partner'>Partner</option>
                    <option value='Public&#x20;Relations'>Public Relations</option>
                    <option value='Sales&#x20;Mail&#x20;Alias'>Sales Mail Alias</option>
                    <option value='Seminar&#x20;Partner'>Seminar Partner</option>
                    <option value='Seminar-Internal'>Seminar-Internal</option>
                    <option value='Trade&#x20;Show'>Trade Show</option>
                    <option value='Web&#x20;Download'>Web Download</option>
                    <option selected value='Web&#x20;Research'>Web Research</option>
                    <option value='Chat'>Chat</option>
                </select>
                <div class='zcwf_col_help'></div>
            </div>
        </div>

        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <div style="display: flex;">
                    <!-- [value]="checkboxValue" -->
                    <input type='checkbox' class='zcwf_ckbox' id='LEADCF6' name='LEADCF6' [value]="checkboxValue"
                        (change)="onCheckboxChange($event)" />
                    <label style="margin-left: 10px;padding-top: 8px;" for="email">Would you like us to call
                        you&#x3f;</label>
                </div>
            </div>
        </div>

        <div [hidden]="!showCaptcha" class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <div class="btn-submit-container form-row">   
                    <div >
                        <re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="form-row justify-content-center">
            <div class="form-group col-md-5 mb-3">
                <div class="btn-submit-container form-row">
                    <button style="text-align: center;" type="submit" id='formsubmit' class="btn-submit" [disabled]="!captcha" >Submit</button>
                </div>
            </div>
        </div>



    </form>
    <div class='wf_customMessageBox' id='wf_splash' style='display:none'>
        <div class="wf_customCircle">
            <div class="wf_customCheckMark"></div>
        </div>
        <span id="wf_splash_info"></span>
    </div>
</div>
